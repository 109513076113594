import React from "react";

// Components
import { Global } from "@emotion/core";
import Layout from "../../components/Layouts/Layout";
import SEO from "../../components/Utilities/SEO";
import SectionKnowBelieveFulfill from "../../components/Sections/SectionKnowBelieveFulfill";

export default () => {
  return (
    <Layout scrollLock>
      <SEO
        title="Know Believe Fulfill | Anana Vestige"
        description="Come discover a lifestyle to KNOW how much value you possess, to BELIEVE in the truth of who you are, and to FULFILL the great task you have been given."
      />
      <Global
        styles={{
          ".nav-item-know": {
            color: "white"
          }
        }}
      />
      <SectionKnowBelieveFulfill />
    </Layout>
  );
};
